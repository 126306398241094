.input {
  background: none;
  border: none;
  padding: 6px 3px 5px 3px;
  margin: 0 5px 0 0;
  outline: none;
  color: white;
  font-weight: 400;
  font-size: 19px;
  font-family: "Inria sans";
  border-bottom: 2px solid transparent;

  &:focus {
    border-color: rgba(255, 255, 255, 0.4);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
}

.ghost {
  position: fixed;
  top: -100px;
}
