.pointer {
  position: absolute;
  transition: all .22s linear;
  pointer-events: none;
  z-index: 1;
  pointer-events: none;
}

.cardContainer {
  position: absolute;
  width: 30vw;
  height: 11vw;
  margin-left: -53%;
  margin-top: -6.2vw;
  z-index: -1;
}