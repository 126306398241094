.container {
  display: flex;
  align-items: center;
  font-family: 'Inria Sans';
  padding: 0 15px;
}

.color {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: 8px;
  
  &.disconnected {
    opacity: .5;
  }

  &.pending {
    opacity: 1;
    border-color: transparent;
    background: url('../../../assets/user-pending.svg') no-repeat center center;
    background-size: 20px 20px;
  }
}

.nickname {
  margin: 8px 0 10px;
  font-size: 16px;
  color: white;
  
  &.disconnected {
    opacity: .5;
  }
}

.me {
  font-size: 13px;
  margin-left: 4px;
  opacity: .5;
  color: white;
}

.itsTurn {
  margin: 2px 0 5px 5px;
}

.isMaster {
  font-size: 18px;
  margin: 2px 0 5px 8px;
}

.disconnectedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../../assets/disconnected.svg') no-repeat center center;
  background-size: 20px 20px;
  margin-left: 3px;
  opacity: .5;
}