.title {
  font-size: 26px;
  text-align: center;
}

.container {
  max-width: 80vw;
}

.steps {
  display: flex;
  list-style: none;
  padding: 0;

  .step:nth-child(1) {
    .image {
      background-image: url("../../assets/tutorial/1.jpg");
    }
  }
  .step:nth-child(2) {
    .image {
      background-image: url("../../assets/tutorial/2.jpg");
    }
  }
  .step:nth-child(3) {
    .image {
      background-image: url("../../assets/tutorial/3.jpg");
    }
  }
  .step:nth-child(4) {
    .image {
      background-image: url("../../assets/tutorial/4.jpg");
    }
  }
}

.step {
  margin: 0 20px;
  flex: 1 1 0;
  position: relative;
}

.image {
  border-radius: 50%;
  box-sizing: border-box;
  border: 6px solid white;
  box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.5);
  background: no-repeat center;
  background-size: cover;
  width: 100%;
  padding-top: calc(100% - 20px);
}

.number {
  font-size: 30px;
  display: inline-block;
  width: 45px;
  padding: 4px 0;
  text-align: center;
  background: white;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 8px;
  border: 6px solid #c30000;
}

.bottom {
  text-align: center;
}
