body {
  background: #202020;
  padding: 0;
  margin: 0;
}

div[id=root] {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
}

.board {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
  
  &.flipped {
    transform: rotate(180deg);
  }
}

.playersContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  .tip {
    color: #5d5d5d;
    font-family: "Inria Sans";
    font-size: 17px;
    text-align: center;
    cursor: default;

    &.flipped {
      transform: rotate(180deg);
    }

    .key {
      display: inline-block;
      padding: 1px 6px 2px 5px;
      background: #2d2d2d;
      border-radius: 5px;
      color: #777777;
      margin: 0 2px;
    }
  }
}

.playersGroup {
  display: flex;
  justify-content: space-evenly;
  height: 48%;
  
  &.upper {
    transform: rotate(180deg);
  }
}