.container {
  position: relative;
  display: inline-flex;
  
  &.block {
    display: flex;
  }
}

.tooltip {
  background: black;
  border-radius: 5px;
  color: white;
  font-family: 'Inria Sans';
  position: absolute;
  transform: translate(-50%, calc(-100% - 10px));
  left: 50%;
  box-sizing: border-box;
  padding: 5px 10px;
  min-width: 50px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 15px;
  
  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    bottom: -3px;
    background: black;
    left: calc(50% - 7px);
    border-radius: 3px;
    transform: rotate(45deg);
  }
}