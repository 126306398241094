.container {
  position: fixed;
  bottom: 5px;
  left: 5px;
}

.button {
  background: rgba(255,255,255,.08) no-repeat 8px center;
  background-size: 15px 15px;
  text-decoration: none;
  color: transparent;
  border-radius: 15px;
  padding: 6px 6px 6px 26px;
  font-family: 'Inria sans';
  font-size: 16px;
  margin-top: 5px;
  display: block;
  max-width: 0;
  overflow: hidden;
  transition: all .2s ease;
  cursor: pointer;
  border: 0;
  white-space: nowrap;
  opacity: .5;
  
  &:hover {
    color: rgba(255,255,255,.5);
    max-width: 200px;
    padding: 6px 10px 6px 30px;
    opacity: 1;
  }
}

.email {
  background-image: url('../../assets/envelope.svg');
}

.tutorial {
  background-image: url('../../assets/book-reference.svg');
}
