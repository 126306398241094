.container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: #c30000;
  padding: 25px 30px;
  color: white;
  font-family: 'Inria sans';
  font-size: 18px;
  min-width: 300px;
  transition: all .5s cubic-bezier(.2, 1, .2, 1);
  transform: translate(calc(100% - 15px), 0);
  
  &:hover {
    transform: none;
    
    &:before {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 3px;
    background: url('../../assets/back.svg') left center no-repeat;
    background-size: 10px;
    opacity: .7;
    transition: all .2s ease;
  }
}

.header {
  padding-bottom: 15px;
}
