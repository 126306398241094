.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}

.message {
  position: relative;
  display: inline-block;
  background: white;
  font-family: 'Inria sans';
  border-radius: 4px;
  max-width: 400px;
  min-width: 200px;
  margin: 10px auto;
  padding: 10px 15px;
  transform: translate(0, -150%) scale(.95, .95);
  opacity: .9;
  
  &.shown {
    animation: showToast 3s;
  }
}

@keyframes showToast {
  0% {transform: translate(0, -150%) scale(.95, .95); opacity: .5}
  6% {transform: translate(0, 0) scale(1, 1); opacity: 1}
  94% {transform: translate(0, 0) scale(1, 1); opacity: 1}
  100% {transform: translate(0, -150%) scale(.95, .95); opacity: .5}
}