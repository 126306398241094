.title {
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.customizationWrapper {
  width: 100%;
}

.codeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full {
  opacity: 0.2;
  pointer-events: none;
}

.code {
  font-size: 45px;
  font-family: 'Inria sans';
  font-weight: 600;
  margin: 0;
  padding: 10px 3px 10px 10px;
  letter-spacing: 10px;
}

.copy {
  background: url('../../assets/copy.svg') no-repeat center center;
  background-size: 26px;
  border: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  margin-top: 3px;
  
  &:hover {
    background-color: rgba(255,255,255,.1);
  }
}

.playerList {
  list-style: none;
  padding: 0;
  margin: 30px 0;
}

.loader {
  display: block;
  width: 15px;
  height: 15px;
  margin: 5px auto 0;
  border-radius: 50%;
  background: url('../../assets/user-pending.svg') no-repeat center center;
  background-size: 23px 23px;
}

.message {
  width: 200px;
  text-align: center;
  margin: 20px auto 40px;
}