.card {
  width: 23%;
  height: 85%;
  font-family: 'Inria sans';
  margin: 0 2%;
  perspective: 500px;
  transition: all .2s;
  flex-shrink: 0;
  
  &:not(.placed) {
    margin: 0 -4%;
  }
  
  &.highlighted {
    .front,
    .back {
      animation: signaling .5s infinite alternate linear;
    }
  }
  
  &:hover {
    .front,
    .back {
      border-color: #FF9800;
    }
  }

  &:hover:not(.flipped):not(.placed):not(:last-child),
  &.held {
    &:not(.held) {
      margin: 0 2.5% 0 -8%;
    }
    
    .inner {
      transform: translate(0, -2px) rotate(-1deg);
    }
    
    .front,
    .back {
      border-color: #FF9800;
    }
  }
  
  &.flipped .inner {
    transform: rotateY(180deg);
  }
  
  &.rotated .inner {
    transform: rotate(180deg);
  }
  
  &.holding {
    .front,
    .back {
      opacity: 0;
    }
  }
  
  &.placed.flags {
    margin-left: 8%;
  }
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all .2s ease-out;
  transform-style: preserve-3d;
}

.front,
.back {
  position: absolute;
  padding: 10%;
  box-sizing: border-box;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  box-shadow: -1px 1px 2px 1px rgba(0,0,0,.2);
  user-select: none;
  font-size: 72%;
  border: 2px solid transparent;
  cursor: pointer;
  overflow: hidden;
}

.back {
  background: no-repeat bottom center;
  background-size: 50%;
  transform: rotateY(180deg);
}

.flags {
  .front,
  .back {
    background-color: #ae1e1e;
    color: white;
  }
  
  .back {
    background-image: url('../../assets/card-back-flags.svg');
  }
}

.perks {
  .front,
  .back {
    background-color: white;
  }

  .back {
    background-image: url('../../assets/card-back-perks.svg');
  }
}

.popup {
  font-family: 'Inria sans';
  font-size: 120%;
  width: 12vw;
  height: 18vw;
  border-radius: .6vw;
  background: white;
  position: fixed;
  box-sizing: border-box;
  padding: 1vw;
  z-index: 2;
  transform: translate(-50%, -105%) scale(.9,.9);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .3);
  pointer-events: none;
  margin-bottom: 100vh;
  opacity: 0;
  transition: transform .07s ease, box-shadow .07s ease;
  overflow: hidden;
  
  &.flags {
    background-color: #ae1e1e;
    color: white;
  }
  
  &.shown {
    margin-bottom: 20px;
    transform: translate(-50%, -110%) scale(1,1);
    box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, .3);
    opacity: 1;
  }
}

.cardHeld {
  position: fixed;
  width: 30vw;
  height: 11vw;
  transform: translate(-8%, -40%);
  pointer-events: none;
}

.line {
  display: block;
  min-height: .8rem;
}

@keyframes signaling {
  from {
    border: 2px solid rgba(255, 219, 0, 1);
    box-shadow: -1px -1px 0px 1px rgba(255, 230, 0, 1);
  }
  to {
    border: 2px solid rgba(255, 219, 0, 0);
    box-shadow: -1px -1px 0px 1px rgba(0,0,0,.2);
  }
}