.container {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:nth-child(2n + 1) {
    background: rgba(255,255,255,.2)    
  }

  & > div {
    align-items: center;
    display: flex;
  } 
}

.crown {
  width: 22px;
  height: 22px;
  background: url('../../../assets/crown.svg') no-repeat center center;
  background-size: 22px 22px;
  border: 0;
  cursor: pointer;
  opacity: .6;
  
  &:hover {
    opacity: 1;
  }
  
  &.active {
    opacity: 1;
    background-image: url('../../../assets/crown-full.svg');
    cursor: default;  
  }
}

.actions {
  align-items: center;
  display: flex;

  button {
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
    background: no-repeat center center;
    background-size: 25px 25px;
    outline: none;
    
    &:hover {
      background-color: rgba(255,255,255,.1)
    }
    
    &.kick {background-image: url('../../../assets/kick.svg')}
    &.accept {background-image: url('../../../assets/check-01.svg')}
    &.reject {background-image: url('../../../assets/cross.svg')}
  }
}

.score {
  display: inline-block;
  padding: 0 10px 0 6px;
  min-width: 10px;
  text-align: center;
  cursor: default;
}

.tooltipConfirm::after {
  content: '';
  position: absolute;
  height: 2px;
  background: rgba(255,255,255,.25);
  border-radius: 2px 2px 0 0;
  width: 95%;
  top: 0;
  left: 4%;
  animation: confirmBar 1.7s linear forwards;
}

@keyframes confirmBar {
  from {width: 100%}
  to {width: 0%}
}