.box {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;

  &.itsTurn {
    background: linear-gradient(transparent, #ff481224, #ff481224);
  }
  
  &.pickable:not(.me) {
    cursor: pointer;
    animation: box-signaling .5s infinite alternate linear;

    &:hover {
      background: rgba(255, 255, 255, .04);
    }
    
    div {
      cursor: pointer !important;
      border-color: transparent !important;
    }
  }
 
  &:not(.me) .hand.collapsed,
  &.me .hand.collapsed:not(:hover) {
    > div {margin: 0 -11%;}
    > div:nth-child(1) {transform: translate(0, 0) rotate(-1.5deg) !important;}
    > div:nth-child(2) {transform: translate(0, 0) rotate(-1deg) !important;}
    > div:nth-child(3) {transform: translate(0, 0) rotate(-0.5deg) !important;}
    > div:nth-child(4) {transform: translate(0, 0) rotate(0deg) !important;}
    > div:nth-child(5) {transform: translate(0, 0) rotate(-0.5deg) !important;}
    > div:nth-child(6) {transform: translate(0, 0) rotate(1deg) !important;}
    > div:nth-child(7) {transform: translate(0, 0) rotate(1.5deg) !important;}
  }
}

.dropZone {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2% 0;
}

.card {
  width: 27%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  &.flag {
    margin-left: 6%;
  }

  &.active:before {
    border-color: rgba(255,255,255,.24)
  }

  &.signaling:before {
    animation: slot-signaling .5s infinite alternate linear;
  }
  
  &.signaling:hover:before {
    background: rgba(255,255,100,.04);
  }
}

.card::before {
  content: "";
  display: inline-block;
  margin: 0 2%;
  border-radius: 10%;
  width: 80%;
  height: 85%;
  border: 2px dashed rgba(255,255,255,.1);
}

.hand {
  height: 45%;
  display: flex;
  justify-content: center;

  &.four {
    > div:nth-child(1) {transform: rotate(-6deg) translate(0,4%);}
    > div:nth-child(2) {transform: rotate(-2deg);}
    > div:nth-child(3) {transform: rotate(2deg);}
    > div:nth-child(4) {transform: rotate(6deg) translate(0,4%);}
  }
  
  &.five {
    > div:nth-child(1) {transform: rotate(-8deg) translate(0,10%);}
    > div:nth-child(2) {transform: rotate(-4deg) translate(0,4%);}
    > div:nth-child(3) {transform: rotate(0deg) translate(0,1.5%);}
    > div:nth-child(4) {transform: rotate(4deg) translate(0,4%);}
    > div:nth-child(5) {transform: rotate(8deg) translate(0,10%);}
  }
  
  &.six {
    > div:nth-child(1) {transform: rotate(-9deg) translate(0,10%);}
    > div:nth-child(2) {transform: rotate(-6deg) translate(0,3%);}
    > div:nth-child(3) {transform: rotate(-2deg);}
    > div:nth-child(4) {transform: rotate(2deg);}
    > div:nth-child(5) {transform: rotate(6deg) translate(0,3%);}
    > div:nth-child(6) {transform: rotate(9deg) translate(0,10%);}
  }
  
  &.seven {
    > div:nth-child(1) {transform: rotate(-9deg) translate(0,8%);}
    > div:nth-child(2) {transform: rotate(-6deg) translate(0,3%);}
    > div:nth-child(3) {transform: rotate(-3deg);}
    > div:nth-child(4) {transform: rotate(0deg) translate(0,-1%);}
    > div:nth-child(5) {transform: rotate(3deg);}
    > div:nth-child(6) {transform: rotate(6deg) translate(0,3%);}
    > div:nth-child(7) {transform: rotate(9deg) translate(0,8%);}
  }
}

.playerInfo {
  margin: -25px auto 0;
  border-radius: 20px;
  z-index: -1;
  
  &.flipped {
    transform: rotate(180deg);
  }
}

.emoteContainer {
  position: absolute;
  bottom: 45%;
  left: 50%;
}

.emote {
  font-size: 65px;
  transform: translate(-50%, 0);
}

.confetti {
  pointer-events: none;
  position: fixed;
}

@keyframes slot-signaling {
  from {border-color: rgba(255,214,0,.24)}
  to {border-color: rgba(255,214,0,.64)}
}

@keyframes box-signaling {
  from {border: 3px dashed rgba(255,255,255,.04)}
  to {border: 3px dashed rgba(255,255,255,.13)}
}