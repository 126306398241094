.button {
  background: white no-repeat center center;
  background-size: 40px 40px;
  color: #c30000;
  padding: 10px 25px;
  margin: 3px 0;
  position: relative;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  border: 0;
  width: 100%;
  font-family: 'Inria sans';
  cursor: pointer;
  outline: none;
  transition: transform .1s ease-out;
  
  &:hover {
    transform: scale(1.02, 1.04)
  }

  &.loading {
    pointer-events: none;
    color: transparent;
    background-image: url('../../assets//spinner.svg')
  }
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(255,0,0,.3);
    border-radius: 2px;
  }

  &.confirming::before {
    height: 3px;
    animation: confirmBar 1.7s linear forwards;
  }
  
  &.small {
    padding: 8px 12px;
    font-size: 16px;
  }
  
  &.wide {
    width: 100%;
    padding: 10px 15px;
  }
  
  &.secondary {
    color: white;
    background-color: #d42727;
    
    &::before {
      background: rgba(255,255,255,.14);
    }
  }
  
  &.disabled {
    opacity: .9;
    color: #dcdcdc;
    pointer-events: none;
  }
}

@keyframes confirmBar {
  from {width: 100%}
  to {width: 0%}
}