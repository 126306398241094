.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(ellipse at center, rgba(0,0,0,.05) 0%, rgba(0,0,0,.3) 100%);;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background: #c30000;
  border-radius: 6px;
  padding: 25px 30px;
  color: white;
  font-family: 'Inria sans';
  font-size: 18px;
  position: relative;
  min-width: 300px;
  
  &.withHeader {
    padding-top: 15px;
  }
}

.header {
  padding-bottom: 20px;
  display: flex;
}

.headerContent {
  flex-grow: 1;
}

.backButton {
  height: 25px;
  width: 25px;
  margin: 5px 0;
  background: url('../../assets/back.svg') left center no-repeat;
  background-size: 20px 20px;
  border: none;
  cursor: pointer;
}