.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  position: absolute;
  top: 38px;
  left: -10px;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  background: #282828;
  box-shadow: 0 5px 10px 3px rgba(0,0,0,.3);
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.dot {
  width: 14px;
  height: 14px;
  margin: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
  
  &.active,
  &:hover {
    border-color: white;
  }
}
