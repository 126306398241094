.container {
  display: flex;
  font-size: 18px;
  text-align: center;

  & > div:not(:last-child) {
    border-right: 3px solid rgba(255,255,255,.4);
    padding-right: 40px;
    margin-right: 40px;
  }
  
  p {
    margin-top: 0;
  }
}

.centeredText {
  margin-top: 0;
  text-align: center;
}

.code {
  padding: 10px 5px 10px 20px;
  margin: 15px;
  margin: 35px 100px 60px 120px;
  overflow: hidden;
  position: relative;
  width: 280px;
  
  input {
    font-family: 'PT Mono';
    background: none;
    border: none;
    outline: none;
    color: white;
    font-weight: 600;
    font-size: 50px;
    letter-spacing: 20px;
    width: 260px;
  }
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -16px;
    width: 240px;
    height: 1px;
    left: 18px;
    border-bottom: 19px dashed rgba(255,255,255,.4);
  }
  
  &::placeholder {
    color: rgba(255,255,255,.4)
  }
}