.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-size: 16px;
}

.popup {
  position: absolute;
  top: 38px;
  right: -5px;
  width: 120px;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #282828;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
}

.bubble {
  padding: 6px 15px;
  background: #d92929;
  color: white;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: #d83737;
  }

  &.inList {
    background: #282828;
    border-radius: 8px;
    padding: 6px 10px;

    &:hover {
      background: #3f3f3f;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
